
import Vue from 'vue';

type CommonType = {
  name: string;
  id: string;
  isGA4?: boolean;
  loaded?: boolean;
};

export default Vue.extend({
  props: ['aid', 'selectedGA', 'gaAccounts'],
  data: (): {
    availableAccounts: CommonType[] | null;
    properties: CommonType[] | null;
    isPropertiesLoading: boolean;
    isViewsLoading: boolean;
  } => ({
    availableAccounts: null,
    properties: null,
    isPropertiesLoading: false,
    isViewsLoading: false,
  }),
  components: { gaPropertyView: () => import('./gaPropertyViewNew.vue') },
  mounted() {
    if (this.gaAccounts) {
      this.availableAccounts = JSON.parse(JSON.stringify(this.gaAccounts));
    }
  },
  methods: {
    getSortedAccounts(): Array<CommonType> {
      if (!this.availableAccounts) {
        return [];
      }

      const selectedAccountId = this.selectedAccount();
      let selectedAccount;
      const sortedAccounts = this.availableAccounts.filter(account => {
        if (account.id === selectedAccountId) {
          selectedAccount = account;
          return;
        }
        return account;
      });
      if (selectedAccount) sortedAccounts.unshift(selectedAccount);
      return sortedAccounts;
    },
    selectView(accountId: number, propertyId: number, viewId: number): void {
      this.$emit('select-view', accountId, propertyId, viewId);
    },
    selectedAccount(): string {
      if (this.selectedGA) {
        return this.selectedGA?.accountId;
      } else return '';
    },
    isAccountChecked(accountId: string): string {
      if (!this.selectedAccount() || this.selectedAccount() !== accountId) return 'grey--text text--lighten-2';
      return 'green--text';
    },
    async loadGaProperties(accountId: string, loaded: boolean): Promise<void> {
      if (!loaded) {
        // get the selected account properties
        const tmpAccount = JSON.parse(JSON.stringify(this.availableAccounts));
        this.isPropertiesLoading = true;
        await this.$store
          .dispatch('linearAdmin/getGaAvailableProperty', {
            id: this.aid,
            accountId,
          })
          .then(data => {
            const transformedData: Record<string, string | boolean>[] = [];
            for (const key in data) {
              const items = data[key];
              const isGA4 = key === 'ga4';
              const isArray = Array.isArray(items);
              if (items.length && isArray) {
                items.forEach(item => {
                  transformedData.push({
                    id: item.id,
                    name: item.name,
                    isGA4,
                  });
                });
              }
            }

            tmpAccount.map(account => {
              if (account.id === accountId) {
                account.loaded = true;
                account.properties = transformedData;
              }
            });
            Vue.set(this, 'availableAccounts', tmpAccount);
          })
          .catch((error: Error) => {
            this.$store.dispatch('showError', error);
            // eslint-disable-next-line no-console
            console.error(error);
          })
          .finally(() => {
            this.isPropertiesLoading = false;
          });
      }
    },

    async loadGAProfiles(accountId: string, propertyId: string, loaded: boolean): Promise<void> {
      if (!loaded) {
        if (this.isPropertiesLoading) return;
        const tmpAccount = JSON.parse(JSON.stringify(this.availableAccounts));
        this.isViewsLoading = true;
        await this.$store
          .dispatch('linearAdmin/getGaAvailablePropertyView', {
            id: this.aid,
            accountId,
            propertyId,
          })
          .then(views => {
            tmpAccount.map(account => {
              if (account.id === accountId) {
                account?.properties?.map(property => {
                  if (property.id === propertyId) {
                    property.views = views?.data;
                    property.loaded = true;
                  }
                });
              }
            });
            Vue.set(this, 'availableAccounts', tmpAccount);
            this.isViewsLoading = false;
          });
      }
    },
  },
});
